<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <g-form @submit="save">
          <b-row class="p-0 mb-1">
            <b-col cols="12">
              <b-button
                :variant="file.name ? 'success' : 'relief-primary'"
                data-action-type="new"
                class="mr-1"
                @click=" (v) => {
                  openModal();
                }
                "
              >
                {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
                <feather-icon
                  icon="UploadIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>

              <a
                v-if="selectedItem.attachmentUrl"
                :href="selectedItem.attachmentUrl"
                class="mr-1 btn-sm btn btn-success"
                @click.prevent="downloadItem(selectedItem)"
              >
                {{ $t("attachedFile") }}
                <feather-icon
                  icon="DownloadIcon"
                  size="15"
                  class="ml-25"
                />
              </a>
            </b-col>

            <b-col
              v-if="selectedItem.postedVoucherId && !this.$route.query.hidePostLabel"
              cols="12"
              class="mt-1 d-flex justify-content-center"
            >
              <b-button
                v-if="transactionType === 'sales'"
                :variant="'dark'"
                data-action-type="new"
                class="mr-1 btn-sm"
                @click="
                  (v) => {
                    this.$router.push({
                      name: 'vouchers-edit',
                      params: { id: selectedItem.postedVoucherId },
                    });
                  }
                "
              >
                {{ `${$t('voucherNum')} ${selectedItem.voucherCode}` }}
              </b-button>
            </b-col>
          </b-row>

          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Spacer -->
            <!-- <hr class="invoice-spacing" /> -->

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
              style="margin-bottom: -30px;"
            >
              <b-row class="invoice-spacing">
                <!-- transaction date  -->
                <b-col
                  v-if="!currentBranch.setDefaultDate"
                  md="4"
                >
                  <g-field
                    :value="getDate(selectedItem.transactionDate)"
                    label-text="date"
                    disabled
                    name="date"
                  />
                </b-col>

                <b-col
                  v-else
                  md="4"
                >
                  <g-picker
                    :value.sync="selectedItem.transactionDate"
                    label-text="date"
                    name="date"
                  />
                </b-col>

                <!-- transaction time  -->
                <b-col md="4">
                  <b-form-group>
                    <g-field
                      label-text="transactionTime"
                      name="transactionTime"
                      readonly
                      :value.sync="selectedItem.transactionTime"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  class="mb-lg-1"
                >
                  <label
                    style="font-size: 14px; margin-bottom: 7px"
                    for="student"
                  >
                    {{ $t("student") }}
                    <feather-icon
                      v-b-tooltip.hover="$t('student-new')"
                      icon="PlusIcon"
                      class="clickable"
                      @click="
                        () => {
                          addExtrnalCustomer();
                        }
                      "
                    />
                  </label>
                  <student-autocomplete ref="autoComlete"
                    :hideLabel="true"
                    :disabled=" this.hasService === true || isClosed || hasSource"
                    :value.sync="selectedItem.studentId"
                    url="students/getStudentsTaxLookup"
                    :display-item="{
                      id: selectedItem.studentId,
                      uid: selectedItem.studentId,
                      arabicName: selectedItem.studentName,
                    }"
                    @change:action="(val) => changeCustomer(val)"
                    style="margin-top: 0px;"
                  />
                </b-col>
              </b-row>
              <b-row class="invoice-spacing">
                <b-col md="4">
                  <g-field
                    field="select"
                    :label="isRight ? 'code' : 'code'"
                    label-text="invoiceCode"
                    name="salesInvoices"
                    :value.sync="invoiceId"
                    :disabled="selectedItem.studentId < 0 || id > 0"
                    itemId='invoiceId'
                    :filterFn="(item) => item.studentId === selectedItem.studentId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="salesInvoices"
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <b-row>
                <b-col cols="12">
                  <div class="d-none d-lg-flex">
                    <b-row class="flex-grow-1 px-2 mb-50">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("#") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("notes") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                        class="service-title"
                      >
                        {{ $t("service") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t("returnValue") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t("remaining") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("discounts") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("tax") }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t("beforeTax") }}
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>
                </b-col>
              </b-row>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="p-50"
                        >
                          <p
                            class="mb-1 service-counter"
                          >
                            {{ index + 1 }}
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.notes"
                            :short-desc="true"
                            size="sm"
                            class="my-0"
                            maxlength="500"
                          />
                          <small class="textarea-counter-value float-right"><span class="char-count">{{
                            item.notes ? item.notes.length : 0
                          }}</span>
                            / 500
                          </small>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                          class="service-feature"
                        >
                          <g-field
                            field="select"
                            rules="required"
                            :clearable="false"
                            :label="isRight ? 'arabicName' : 'englishName'"
                            :value.sync="item.id"
                            :dir="isRight ? 'rtl' : 'ltr'"
                            :options="itemsData"
                            :selectable="(service) => !service.stopped"
                            :placeholder="$t('selectService')"
                            @change="(val) => {
                              item.isTaxable = val.isTaxable;
                              item.code = val.code;
                              item.serviceId = val.id;
                              item.price = val.serviceFees;
                              item.net = val.serviceFees;
                              item.total = val.serviceFees;
                              item.lineSerial = index + 1;
                              item.priceNotIncludeTax = val.priceNotIncludeTax;
                              computeTaxAndPriceBeforeTax(item);
                              item.tax = item.tax;
                              item.discountValue = val.discountValue;
                              item.discountPercentage = val.discountPercentage;
                              selectedItem.invoiceLines = invoiceData.items;
                              item.fixedPrecentage = val.isFeesFixed;
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.price"
                            type="number"
                            rules="required"
                            :disabled="item.fixedPrecentage === true"
                            class="mb-1"
                            @input="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({ item: item });
                            }
                            "
                            @change="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({ item: item });
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.returnValue"
                            disabled
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.discountValue"
                            type="number"
                            class="mb-1"
                            @input="(v) => {
                              computeTaxAndPriceBeforeTax(item);
                              computeTotal({
                                item: item,
                                discountValue: item.discountValue,
                              });
                            }
                            "
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.tax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.priceBeforeTax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                      </b-row>
                      <div class="d-flex align-items-center border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="TrashIcon"
                          class="cursor-pointer"
                          stroke="firebrick"
                          @click="removeItem(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItemInItemForm"
              >
                {{ $t("addService") }}
                <feather-icon
                  icon="CloudLightningIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>
            </b-card-body>
          </b-card>
        </g-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row>
            <b-modal
              ref="upload-modal"
              no-close-on-backdrop
              cancel-variant="outline-secondary"
              centered
              size="lg"
              hide-footer
              :title="$t('attachFile')"
            >
              <g-form>
                <b-row>
                  <b-col cols="12">
                    <div>
                      <input
                        type="file"
                        class="excel-upload-input"
                        accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip"
                        @change="handleFileUpload($event)"
                      >
                      <b-alert
                        v-if="file"
                        show
                        fade
                        class="mt-2 text-center"
                        variant="success"
                      >
                        <div class="alert-body">
                          <span>{{ $t("importSuccsefly") }} {{ file.name }}</span>
                        </div>
                      </b-alert>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col
                    cols="12"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      class="mx-1"
                      variant="primary"
                      @click="closeModal"
                    >
                      {{ $t("save") }}
                    </b-button>
                    <b-button
                      class="mx-1"
                      variant="danger"
                      data-action-type="delete"
                      :disabled="!file"
                      @click="
                        () => {
                          removeAttachment();
                        }
                      "
                    >
                      {{ $t("cancel") }}
                    </b-button>
                  </b-col>
                </b-row>
              </g-form>
            </b-modal>
          </b-row>
          <b-row class="m-auto">
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap">
                {{ $t("total") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap">
                {{ $t("discont") }}
              </span>
            </b-col>
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="
                  (v) => {
                    selectedItem.discountValue < 0 ||
                      selectedItem.discountValue > selectedItem.total
                      ? (v = 0)
                      : v;
                    selectedItem.discountValue = v;
                    selectedItem.discountPercentage = 0;
                    computeDiscount({ value: v });
                  }
                "
              />
            </b-col>
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="!total || isLineBaseDiscount"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                @input="
                  (v) => {
                    selectedItem.discountPercentage < 0 ||
                      selectedItem.discountPercentage > 100
                      ? (v = 0)
                      : v;
                    selectedItem.discountPercentage = v;
                    computeDiscount({ Percentage: v });
                  }
                "
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("netBeforeTax") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.netBeforeTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("tax") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.totalTaxes)"
                size="sm"
                placeholder="total"
                name="total"
                type="number"
                disabled
                readonly
              />
            </b-col>
            <!-- discount -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder">
                {{ $t("net") }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card class="border sticky-content-top2">
          <b-row>
            <b-button
              v-permission="'addSalesTransactions'"
              class="mx-1 btn-block"
              variant="outline-primary"
              data-action-type="saveAndPrint"
              :disabled="selectedItem.invoiceLines.length <= 0"
              @click="save('saveAndPrint')"
            >
              <feather-icon
                icon="PrinterIcon"
                size="15"
                class="mr-25"
              />
              {{ $t("saveAndPrint") }}
            </b-button>

            <b-button
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              :disabled="selectedItem.invoiceLines.length <= 0"
              @click="save"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-25"
              />
              {{ $t("save") }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- <invoice-sidebar-add-new-customer /> -->
  </section>
</template>
  <script>
// import Logo from '@core/layouts/components/Logo.vue';
import invoiceMixin from '@/mixin/invoiceMixin';
// import { ref, onUnmounted } from '@vue/composition-api';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import saveAs from 'file-saver';
// import store from '@/store';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BFormGroup,
  VBToggle,
} from 'bootstrap-vue';
import directPrintPOSUtils from '@/com-functions/directPrintForIPOS';
import createRunTimeComponent from '@/components/RenderStringAsComponent';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';
// import invoiceStoreModule from './invoiceStoreModule';
//   import InvoiceSidebarAddNewCustomer from './InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    StudentAutocomplete,
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition, invoiceMixin, reportMixin],
  props: ['id'],
  data() {
    return {
      services: [],
      returnService: [],
      returnValue: 0,
      isPricegGreterThanReturnValue: true,
      invoiceId: null,
      hasService: false,
      studentTaxable: true,
      nationalityTaxable: false,
      invoiceType: 'ReturnSales',
      isRequested: false,
      // posInvoiceDesign: posInvoiceDesign,
      template: '',
      file: '',
      currentLogo: localStorage.COMAPNY_LOGO,
      bankName: 0,
      banks: [],
      fullscreen: false,
      teleport: true,
      pageOnly: false,
      transactionType: 'sales',
      payemntType: 'Cash',
      paymentMethod: {
        value: null,
        cashPaid: 0,
        id: null,
        arabicName: '',
        englishName: '',
      },
      safeTransactions: {},
      customer: {},
      payment: {
        invoiceId: 0,
        lineSerial: 0,
        value: 0,
        paymentMethod: 'safe',
        safeId: 0,
        otherPaymentMethodId: 0,
        referenceNumber: 'string',
      },
      fromTransactionType: '',
      items: [],
      errors: [],
      pendingItem: {},
      pendingItems: [],
      isCalculatingDiscount: false,
      isCalculatingUnTaxableDiscount: false,
      checkPaymentSalesOrder: false,
      qrVal: '',
      currentUser: localStorage.USERNAME,
      itemsData: [],
      salesInvoices: [],
      returnValues: [],
      invoiceData: {
        items: [{
          itemArabicName: '',
          itemEnglishName: '',
          code: '',
          serviceId: '',
          quantity: 0,
          price: 0,
          total: 0,
          net: 0,
          fixedPrecentage: false,
          tax: 0,
          priceBeforeTax: 0,
          discountValue: 0,
          discountPercentage: 0
        }]
      }
    };
  },
  computed: {
    canAddNewPayment() {
      return (this.remaindered && this.paymentMethod.value > 0 && this.paymentMethod.value <= this.remaindered)
        && ((this.payemntType === 'Cash' && this.safeTransactions) || this.paymentMethod.otherPaymentMethodId);
    },
    isCash() {
      return this.selectedItem.paymentType === 'cash';
    },
    total() {
      return this.invoiceData.items.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
    },
    paid() {
      return this.selectedItem.invoicePayments.reduce((sum, item) => {
        // sum += parseFloat(item.cashPaid);
        item.totalValue = this.selectedItem.net
        if (item.paymentMethod === 'cash') {
          item.value = item.cashPaid
        }
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
    remaindered() {
      return this.fraction(this.selectedItem.net - this.paid) || 0;
    },
    tableColumnsInvoice() {
      return [
        { key: 'transactionDate', label: this.$t('transactionDate'), sortable: true },
        {
          key: 'paymentType',
          label: this.$t('paymentType'),
          sortable: true,
          isLocale: true,
        },
        {
          key: 'netBeforeTaxes',
          label: this.$t('netBeforeTax'),
          sortable: true,
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },
  watch: {
    fullscreen(newVal) {
      if (newVal === true) {
        document.getElementById('virticalmenu').classList.add('display');
        document.getElementById('navBar').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
        document.getElementById('navBar').classList.remove('display');
      }
    },
    'selectedItem.invoiceLines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0) this.selectedItem.invoicePayments = [];
    },
    remaindered(newVal) {
      this.paymentMethod.value = newVal;
      this.paymentMethod.cashPaid = newVal;
    },
    total(newval) {
      this.selectedItem.total = newval;
      this.computeTotals();
      if (this.selectedItem.invoicePayments.length && this.selectedItem.invoiceLines.length < 1) {
        this.selectedItem.invoicePayments = [];
      }
    },
    'selectedItem.total'(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.customer.discount) {
          this.computeDiscount({ Percentage: this.selectedItem.discountPercentage });
        } else {
          this.computeDiscount({ value: this.selectedItem.discountValue });
        }
      }
    },
  },
  beforeMount() {
    this.updateViewModel('purch');
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.GetAllInvoiceNotHaveRetruenInvoice();
    this.selectedItem.transactionDate = this.getDate();
    this.selectedItem.transactionTime = this.getTime();
    this.selectedItem.branchId = this.branchId;
    // this.getBanks();
    this.hasClass();
    this.sourceId = this.selectedItem.sourceTransactionId;
    this.updateViewModel();
    const id = this.id || this.selectedItem.sourceTransactionId;
    if (id > 0) {
      let transactionType = this.$route.query.sourceTransactionType;
      if (this.id) transactionType = this.transactionType;
      this.get({ url: 'Invoices', id: id }).then((data) => {
          var liness = data.invoiceLines;
          this.selectedItem.invoiceLines.push(...data.invoiceLines);
          this.selectedItem = data;
          liness.forEach((item) => {
            var newPrice = this.fraction((Number(item.price) - Number(item.returnValue)));
           // var newReturnValue = this.fraction((Number(item.price) - Number(item.returnValue)));
           var ret = this.fraction((Number(item.price) - Number(item.returnValue)));
            if (Number(ret) > 0) {
              this.returnValues.push(item.returnValue);
              item.price = newPrice;
             // item.returnValue = newReturnValue;
              this.computeTaxAndPriceBeforeTax(item);
              this.computeTotal({ item: item });
              this.services.push(item);
            }
          })
          this.selectedItem.invoiceLines = this.services;
          if (this.returnValues.length <= 0) {
            this.selectedItem.net = 0;
            this.selectedItem.total = 0;
            this.selectedItem.netBeforeTaxes = 0;
            this.selectedItem.totalTaxes = 0;
            this.selectedItem.discountValue = 0;
            this.selectedItem.discountPercentage = 0;
          }
          this.invoiceId = this.selectedItem.sourceTransactionId;
          if (!this.invoiceId) {
            this.invoiceId = this.$route.query.sourceTransactionId;
          }
         if (this.id != null) {
          var salin = {
            invoiceId: this.invoiceId, studentId: data.studentId, code: data.salesCode
          }
          this.salesInvoices.push(salin);
        }
          data.invoiceLines.forEach((item) => {
            item.id = item.serviceId;
          })
          this.invoiceData.items = data.invoiceLines;
          for (let index = 0; index < this.invoiceData.items.length - 1; index++) {
            this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight);
              setTimeout(() => {
                this.$refs.form.style.overflow = null;
              }, 350);
            });
          }
        }).then(() => {
        if (this.sourceId > 0) this.prepareItemfromSource(transactionType);
      })
    }
    this.itemsSearchProvider();
    this.initTrHeight();
    this.computeTotals();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    checkReturnValue(returnValue, price) {
      if ((Number(price) > Number(returnValue))) {
          this.doneAlert({ text: this.$t('returnValueMustGreterThanOrEqualPrice'), type: 'warning' });
          this.isPricegGreterThanReturnValue = false;
        }
       this.isPricegGreterThanReturnValue = true;
    },
    checkServicesBeforeAdd() {
      var retValue = this.invoiceData.items.find(element => (Number(element.price) > Number(element.returnValue) && Number(element.returnValue) > 0));
      if (retValue && retValue.returnValue > 0) {
        this.doneAlert({ text: this.$t('returnValueMustGreterThanOrEqualPrice'), type: 'error' });
        retValue = null;
        return false;
      }
      return true;
    },
    checkIsInvoiceHasBeenRefundedInFull() {
      var val = this.invoiceData.items.find(element => Number(element.returnValue) > 0) || {};
      if (!val) {
        this.doneAlert({ text: this.$t('theInvoiceHasBeenConvertedToARefund'), type: 'error' });
        return false;
      }
      return true;
    },
    checkHasInvoice() {
      if (this.invoiceId === null) {
        this.doneAlert({ text: this.$t('mustSalectSaleForRetuenSales'), type: 'error' });
        return false;
      }
      return true;
    },
    GetAllInvoiceNotHaveRetruenInvoice() {
      this.get({
        url: 'invoices/invoiceCode',
      }).then((data) => {
        this.salesInvoices = data;
      })
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file)
        .then((file1) => {
          this.selectedItem.imageBase64Content = file1.split(',').pop();
        });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name)
    },
    itemsSearchProvider() {
      this.get({ url: 'services' }).then((data) => {
        if (this.id) {
          this.itemsData = data.filter((val) => val.stopped === false || this.selectedItem.invoiceLines.indexOf(x => x.serviceId === val.id) === -1);
        } else {
          this.itemsData = data.filter((val) => val.stopped === false);
        }
      })
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    addExtrnalItem() {
      var myWindow = window.open('/#/items/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          // this.refreshTable()
        }
      }, 500);
    },
    addExtrnalCustomer() {
      var myWindow = window.open('/#/students/new', '', `width=${window.screen.availWidth}height=${window.screen.availHeight}`);
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          window.location.reload();
        }
      }, 500);
    },
    limit(event, limit) {
      if (this.selectedItem.notes.length >= limit) {
        event.preventDefault();
      }
    },
    hasClass() {
      if (this.fullscreen === true) {
        document.getElementById('virticalmenu').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
      }
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    toggleApi() {
      this.$fullscreen.toggle(this.$el.querySelector('.fullscreen-wrapper'), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
      })
    },
    prepareItemfromSource(sourceTransactionType) {
      this.fromTransactionType = sourceTransactionType;
      this.selectedItem.id = 0;
      this.selectedItem.transactionType = this.transactionType;
      // this.selectedItem.invoiceLines = this.selectedItem.invoiceLines.map((item) => {
      //   return {
      //     ...item,
      //     consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
      //     originalQuantity: item.quantity,
      //     quantity: item.quantity - (item.consumedQuantity || 0),
      //   };
      // });
      // this.selectedItem.invoiceLines = this.selectedItem.invoiceLines.filter((item) => { return item.quantity > 0; })
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.transactionTime = this.time;
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.code = null;
      // if (sourceTransactionType === 'salesOrder') {
      //   this.selectedItem.invoicePayments = [];
      //   this.selectedItem.paymentType = 'credit';
      // }
      this.selectedItem.sourceTransactionId = this.sourceId;
    },
    togglePendingItem() {
      if (this.selectedItem.invoiceLines && this.selectedItem.invoiceLines.length > 0) {
        const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
        this.pendingItems.push(pendingItem);
        this.doneAlert({
          title: this.$t('pendingSucess'),
          type: 'success',
        });
        this.getSelecteItemObj();
      } else {
        this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
      }
    },
    retriveInvoice(item) {
      this.pendingItems = this.pendingItems.filter((v) => v !== item);
      this.selectedItem = item;
      this.$refs['invoices-modal'].hide();
    },
    changeCustomer(v) {
      if (!this.id) {
        // this.selectedItem.agentId = v.agentId || null;
        // this.selectedItem.onTheFlyDealerName = '';
      }
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
      this.customer = v;
      this.nationalityTaxable = v.isTaxable;
      this.selectedItem.nationalityTaxable = v.isTaxable;
      this.selectedItem.discountPercentage = this.customer.discount;
     // this.computeDiscount({ Percentage: this.selectedItem.discountPercentage })
      // this.computeDiscount({});
    },
    getSummary() {
      var totalPrice = 0;
      var totalDiscount = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.invoiceLines.forEach((item) => {
        var ret = this.fraction((Number(item.price) - Number(item.returnValue)));
        if (Number(ret) > 0) {
        this.selectedItem.discountValue += parseFloat(item.discountValue) || 0;
        totalPrice += item.total;
        totalDiscount += parseFloat(item.discountValue) || 0;
        }
      });
      this.selectedItem.discountPercentage = this.fraction(((totalDiscount / totalPrice) * 100).toFixed(2));
      if (!Number(this.selectedItem.discountPercentage)) {
        this.selectedItem.discountPercentage = 0
      }
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = Number(item.price);
      item.net = Number(item.total);
      item.discountValue = Number(discountValue);
      if (Number(discountPercentage) > 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(2);
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue > 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(2);
        item.discountValue = discountValue;
        item.net -= (discountValue || 0);
        if (this.selectedItem.discountValue <= 0) {
          this.selectedItem.discountValue = 0;
          this.selectedItem.discountValue = (discountValue || 0);
        } else {
          this.selectedItem.discountValue;
        }
      } else {
        item.net -= item.discountValue || 0;
        item.discountPercentage = ((item.discountValue / item.total) * 100).toFixed(2);
        if (!Number(item.discountPercentage)) {
        item.discountPercentage = 0
      }
      }
      this.getSummary();
    },
    computeTaxAndPriceBeforeTax(item) {
     // this.checkReturnValue(item.returnValue, item.price);
      this.hasService = true;
      if (this.id > 0) {
        this.nationalityTaxable = this.selectedItem.studentTaxable;
      }
      this.itemTaxable = item.isTaxable;

      if (!this.nationalityTaxable) {
        if (item.isTaxable) {
          if (item.discountValue > 0) {
            // check price not include tax
            if (item.priceNotIncludeTax) {
              item.priceBeforeTax = (item.price - item.discountValue).toFixed(
                2
              );
              item.tax = ((item.price - item.discountValue) * 0.15).toFixed(2);
            } else {
              item.priceBeforeTax = ((item.price - item.discountValue) / 1.15).toFixed(2);
              item.tax = (item.price - item.discountValue - (item.price - item.discountValue) / 1.15).toFixed(2);
            }
          } else {
            // check price not include tax
            if (item.priceNotIncludeTax) {
              item.priceBeforeTax = item.price.toFixed(2);
              item.tax = (item.price * 0.15).toFixed(2);
            }
            // check price include tax
            if (!item.priceNotIncludeTax) {
              item.priceBeforeTax = (item.price / 1.15).toFixed(2);
              item.tax = (item.price - item.price / 1.15).toFixed(2);
            }
          }
        } else {
          item.tax = 0;
          if (item.discountValue > 0) {
            item.priceBeforeTax = (item.price - item.discountValue).toFixed(2);
          } else {
            item.priceBeforeTax = item.price;
          }
        }
      } else {
        this.studentTaxable = true;
        if (item.discountValue > 0) {
          // check price not include tax
          if (item.priceNotIncludeTax) {
            item.priceBeforeTax = (item.price - item.discountValue).toFixed(2);
            item.tax = ((item.price - item.discountValue) * 0.15).toFixed(2);
          } else {
            item.priceBeforeTax = ((item.price - item.discountValue) / 1.15).toFixed(2);
            item.tax = (item.price - item.discountValue - (item.price - item.discountValue) / 1.15
            ).toFixed(2);
          }
        } else {
          // check price not include tax
          if (item.priceNotIncludeTax) {
            item.priceBeforeTax = item.price.toFixed(2);
            item.tax = (item.price * 0.15).toFixed(2);
          }
          // price include tax
          if (!item.priceNotIncludeTax) {
            item.priceBeforeTax = (item.price / 1.15).toFixed(2);
            item.tax = (item.price - item.price / 1.15).toFixed(2);
          }
        }
      }
    },
    computeTotals() {
      if (this.id > 0) {
        this.selectedItem.nationalityTaxable = this.selectedItem.studentTaxable;
        if (this.selectedItem.totalTaxes > 0) {
          this.studentTaxable = true;
        } else {
          this.studentTaxable = false;
        }
      }
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.invoiceLines.forEach((invoice) => {
        if (!this.nationalityTaxable) {
          if (invoice.isTaxable) {
            this.selectedItem.netBeforeTaxes += (parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue)).toFixed(2);
          } else { this.selectedItem.netBeforeTaxes += invoice.priceBeforeTax; }
        } else { this.selectedItem.netBeforeTaxes += (parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue)).toFixed(2); }
      });
      // get discounted
      this.computeTotalDiscount();
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.total = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.computeTotalTaxes();
      // this.selectedItem.netBeforeTaxes = this.fraction(Number(this.selectedItem.netBeforeTaxes));
      // this.selectedItem.net = this.fraction(this.selectedItem.net)
      this.selectedItem.totalTaxes = this.fraction(this.selectedItem.totalTaxes);
    },
    computeDiscount({ value, Percentage }) {
      if (!this.selectedItem.total) return;
      if (this.isCalculatingDiscount) return;
      this.isCalculatingDiscount = true;
      if (!value) return;
      value = value || 0;
      Percentage = Percentage || 0;
      if (value) {
        this.selectedItem.discountPercentage = this.fraction((value / parseFloat(this.selectedItem.total)) * 100);
      } else {
        this.selectedItem.discountValue = this.fraction((parseFloat(this.selectedItem.total) * Percentage) / 100);
      }
      // compute totals after apply discount
      // this.computeTotals();
      this.isCalculatingDiscount = false;
    },
    save(typeInvoice) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      if (!this.checkHasInvoice()) return;
      if (!this.beforeSaveValidationDealer()) return;
      if (!this.checkServicesBeforeAdd()) return;
      if (!this.checkIsInvoiceHasBeenRefundedInFull()) return;
      // var retValue = this.invoiceData.items.find(element => (Number(element.net) > Number(element.returnValue) && Number(element.returnValue) > 0));
      // if (retValue && retValue.returnValue > 0) {
      //   this.doneAlert({ text: this.$t('returnValueMustGreterThanOrEqualPrice'), type: 'error' });
      //   retValue = null;
      //   return;
      // }
      // var val = this.invoiceData.items.find(element => Number(element.returnValue) > 0);
      // if (!val) {
      //   this.doneAlert({ text: this.$t('theInvoiceHasBeenConvertedToARefund'), type: 'error' });
      //   return;
      // }
      this.invoiceData.items.forEach((item) => {
        if (Number(item.returnValue) > 0) {
          this.returnService.push(item);
      }
    });
    if (!this.selectedItem.discountPercentage) {
      this.selectedItem.discountPercentage = 0;
    }
      this.selectedItem.sourceTransactionId = this.invoiceId;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      this.invoiceData.items.forEach(element => {
        if (!element.serviceId) {
          this.removeItem(element.lineSerial - 1, element)
        }
        if (!this.selectedItem.sourceTransactionId) {
        if (element.priceNotIncludeTax) {
          element.net = parseFloat(element.net) + parseFloat(element.tax)
        }
      }
      if (this.returnService.length > 0) {
        this.selectedItem.invoiceLines = this.returnService
      } else {
        this.selectedItem.invoiceLines = this.invoiceData.items
      }
    });
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return
      }
      // if saveInvoiceWithRemainder
      if (this.isCash && this.remaindered > 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('you Dont Have Perm Save Invoice With Remainder'),
        });
        return;
      }
      this.isRequested = true
      const url = `Invoices/${this.invoiceType}`;
      (this.id > 0 ? this.update({ url, id: this.id, data: this.getModel() }).then(() => {
        if (typeInvoice === 'saveAndPrint') {
          this.print(this.id);
        }
        this.$router.push({ name: 'invoices' });
      })
        : this.create({ url, data: this.getModel() }))
        .then((dataId) => {
          // if edit or from another source go to new after save
          if (typeInvoice === 'saveAndPrint') {
            this.print(dataId)
          }
          if (this.selectedItem.sourceTransactionId > 0) {
            this.$router.push({ name: 'invoices' });
          } else {
            this.getSelecteItemObj();
            this.invoiceData.items = [];
            this.addNewItemInItemForm();
            this.file = ''
          }
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          this.hasService = false;
        }).catch(({ data }) => {
          this.doneAlert({ title: data.detail, type: 'error' });
        }).finally(() => {
          this.isRequested = false;
          this.getSelecteItemObj();
        })
    },
    saveAndPrint() {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      if (this.currentBranch.sellingBelowCost === 'warning') {
        this.doneAlert({
          text: this.$t('possibilityOfSellingLessThanCostPriceInBranchSettings'),
          type: 'warning'
        });
      }
      if (!this.beforeSaveValidation()) return;
      if (!this.beforeSaveValidationDealer()) return;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return;
      }
      // if saveInvoiceWithRemainder
      if (!this.$can('saveInvoiceWithRemainder') && this.remaindered > 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('you Dont Have Perm Save Invoice With Remainder'),
        });
        return;
      }
      if (this.isCash && this.selectedItem.invoicePayments.length < 1) {
        this.doneAlert({
          type: 'error',
          text: this.$t('mustEnterOnePaymentAtLeast'),
        });
        return;
      }
      this.isRequested = true;
      const url = `ItemTransactions/${this.transactionType}`;
      const receiptData = JSON.parse(JSON.stringify(this.selectedItem));
      if (this.selectedItem.secretCode) {
        this.selectedItem.secretCode = this.stringTobase64(this.selectedItem.secretCode);
      }
      (this.id > 0 ? this.update({ url, id: this.id, data: this.getModel() })
        : this.create({ url, data: this.selectedItem })).then((data) => {
          this.invCode = data.code;
          this.qrtime = new Date().toISOString(); // this.updatedToday;
          this.tag1 = this.d2h(1);
          this.length1 = this.d2h(this.company.englishName.length);
          this.tag2 = this.d2h(2);
          this.length2 = this.d2h(this.company.taxNumber.length);
          this.tag3 = this.d2h(3);
          this.length3 = this.d2h(this.qrtime.length);
          this.tag4 = this.d2h(4);
          this.length4 = this.d2h(receiptData.net.length);
          this.tag5 = this.d2h(5);
          this.length5 = this.d2h(receiptData.totalTaxes.length);
          this.hexVal = `${this.hex_to_ascii(this.tag1)}${this.hex_to_ascii(this.length1)}${this.company.englishName}${this.hex_to_ascii(this.tag2)}${this.hex_to_ascii(this.length2)}${this.company.taxNumber}${this.hex_to_ascii(this.tag3)}${this.hex_to_ascii(this.length3)}${this.qrtime}${this.hex_to_ascii(this.tag4)}${this.hex_to_ascii(this.length4)}${receiptData.net}${this.hex_to_ascii(this.tag5)}${this.hex_to_ascii(this.length5)}${receiptData.totalTaxes}`
          this.qrVal = Buffer.from(this.hexVal).toString('base64') // window.btoa(this.hexVal);
          setTimeout(() => {
            const qrCodeImg = document.querySelector('.test img').src;
            this.template = createRunTimeComponent({
              template: directPrintPOSUtils.createReceiptContent(this.posInvoiceDesign),
              data: {
                address: this.company.address,
                taxNumber: this.company.taxNumber,
                taxPercentage: receiptData.totalTaxes,
                total: receiptData.total,
                discount: receiptData.discountPercentage,
                net: receiptData.net,
                companyLogo: `${this.baseUrl}${this.currentLogo}`,
                selectedItem: receiptData,
                companyName: this.isRight ? this.company.arabicName : this.company.englishName,
                invoiceCode: this.invCode,
                taxesInvoice: this.$t('taxesInvoice'),
                netBeforeTax: receiptData.netBeforeTaxes,
                transactionDate: `${this.time} ${this.today}`,
                paymentType: receiptData.paymentType === 'cash' ? this.$t('cash') : this.$t('notCash'),
                userName: this.currentUser,
                qrCodeImg,
              }
            });
            setTimeout(() => { this.$htmlToPaper('printMe'); }, 1500);
          }, 1500)
        }).then(() => {
          // if edit or from another source go to new after save
          if (this.selectedItem.sourceTransactionId > 0) {
            this.$router.push({ name: 'invoice-list' });
          } else {
            this.getSelecteItemObj();
          }
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
        }).catch(({ data }) => {
          this.doneAlert({ title: data.detail, type: 'error' });
        }).finally(() => {
          this.isRequested = false
        })
    },
    print(id) {
      const printedItem = {
        id: id
      }
      this.printReport(this.isRight ? 'ReturnSales-ar' : 'ReturnSales-en', printedItem);
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push(
        {
          itemArabicName: '',
          itemEnglishName: '',
          code: '',
          serviceId: '',
          quantity: 0,
          price: 0,
          total: 0,
          net: 0,
          notes: '',
          tax: 0,
          priceBeforeTax: 0
        }
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(iex, item) {
      this.invoiceData.items.splice(iex, 1);
      this.selectedItem.discountValue -= !item.discountValue ? 0 : item.discountValue;
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.invoiceData.items.length <= 0) {
        this.selectedItem.invoiceLines = [];
        this.hasService = false;
        this.selectedItem.discountValue = 0;
        this.selectedItem.discountPercentage = 0;
    }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
  </script>
  <style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style><style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

@media (max-width: 768px) {
  .sticky-content-top span {
    margin-bottom: 10px;
  }
}

.service-title {
  width: min-content;
  background-color: #918fdf;
  border: 1px solid #FFF;
  color: #FFF;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
}

.repeater-form .service-feature {
  padding-left: 3px;
  padding-right: 3px;
}

.repeater-form .service-counter {
  width: 20px;
  border: 1px solid;
  border-radius: 40%;
  text-align: center;
  background-color: antiquewhite;
  margin: 0 auto;
}
</style>
